import { Article } from '@/contentful/articles'

import { taxonomyManager } from './marketing'

const sanitizeUrlSegment = (segment: string): string =>
  segment
    .toLowerCase()
    .replace(/\//g, '-')
    .replace(/[&\s]+/g, '-')
    .replace(/--+/g, '-')
    .replace(/^-|-$/g, '')
    .trim()

const removeLeadingSlash = (path: string): string => path.replace(/^\//, '')

const findSubcategories = (label: string): string[] | undefined => {
  if (!label) return undefined

  return taxonomyManager.categories
    .flatMap((category) =>
      category.subcategories
        ?.filter((subcategory) => subcategory.label === label)
        .map((subcategory) => `${category.label}: ${subcategory.label}`)
    )
    .filter(Boolean)
}

/**
 * Counts the number of occurrences of reference subcategories within the articles subCategory field.
 *
 * @param {Article[]} articles - An array of articles
 * @param {string[]} search - An array of subcategories to search for in the articles array
 * @returns {number} The total number of times any of the reference subcategories appear in the articles array.
 */
const countExisting = (articles: Article[], search: string[]) => {
  return articles.reduce(
    (count, article) =>
      count +
      (article.subCategory?.filter((subCat) => search.includes(subCat))
        .length || 0),
    0
  )
}

export const contentConfig = {
  categoryPath: (categoryName: string) =>
    `/categories/${sanitizeUrlSegment(categoryName)}`,
  subcategoryPath: (categoryName: string, subcategoryName: string) =>
    `/categories/${sanitizeUrlSegment(categoryName)}/subcategories/${sanitizeUrlSegment(subcategoryName)}`,
  articlePath: (articleSlug: string) =>
    `/articles/${removeLeadingSlash(articleSlug)}`,
  findSubcategories: (label: string) => findSubcategories(label),
  countExisting: (articles: Article[], search: string[]) =>
    countExisting(articles, search),
}
